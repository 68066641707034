<template>
  <div>
    <data-usage-analytics title="Data Usage" _key="All" :id="id" />
    <data-usage-analytics
      title="Data Usage By Telco"
      _key="Telco"
      :id="id"
      :params="TimeSeriesByTelcoParams"
    />
    <v-row class="mb-10 align-stretch">
      <v-col cols="6">
        <distribution-card
          :title="'Top Usage By Telco'"
          :linked="true"
          type="content"
          _key="TopTelco"
          :id="id"
          :params="TopMetricsByTelcoParams"
        />
      </v-col>
      <v-col cols="6">
        <distribution-card
          :title="'Top Usage By FQDN'"
          :linked="true"
          type="content"
          _key="TopFQDN"
          :id="id"
          :params="TopMetricsByTopFQDNParam"
        />
      </v-col>
    </v-row>
    <v-row class="mb-10 align-stretch">
      <v-col cols="6">
        <distribution-card
          :title="'Top Usage By User'"
          :linked="true"
          type="content"
          _key="TopUser"
          :id="id"
          :params="TopMetricsByUserParams"
        />
      </v-col>
    </v-row>
    <data-usage-analytics
      title="Data Usage By FQDN"
      _key="FQDN"
      :id="id"
      :params="TimeSeriesByFQDNParams"
    />
  </div>
</template>
<script>
import DataUsageAnalytics from '@/components/cards/DataUsageAnalytics'
import DistributionCard from '@/components/cards/DistributionCard'
export default {
  name: 'AppAnalytic',
  components: {
    'data-usage-analytics': DataUsageAnalytics,
    'distribution-card': DistributionCard,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      TimeSeriesByTelcoParams: {
        group_by: 'telco',
      },
      TimeSeriesByFQDNParams: {
        group_by: 'fqdn',
      },
      TopMetricsByTelcoParams: {
        query_type: 'telco',
        topk: 5,
      },
      TopMetricsByTopFQDNParam: {
        query_type: 'fqdn',
        topk: 10,
      },
      TopMetricsByUserParams: {
        query_type: 'user',
        topk: 10,
      },
    }
  },
  destroyed() {
    this.$store.dispatch('analytics/clear')
  },
}
</script>
<style lang="scss"></style>
